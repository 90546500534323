import { createApp } from 'vue'
import App from './App.vue'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth';
import './css/index.css'
import router from './router'
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyBqF4Freloo2otjq6xbROH-lvTVZaE_wFw',
    authDomain: 'cityscaper-1575020315398.firebaseapp.com',
    databaseURL: 'https://cityscaper-studio.europe-west1.firebasedatabase.app',
    projectId: 'cityscaper-1575020315398',
    storageBucket: 'cityscaper-studio',
    messagingSenderId: '402653035990',
    appId: '1:402653035990:web:af5ce811cb10b3e2a4f877',
    measurementId: 'G-RYP9PY1PTM'
}



const fbApp = initializeApp(firebaseConfig)

let app: any

const auth = getAuth(fbApp);

auth.onAuthStateChanged(user => {
    if (!app) {
      app = createApp(App).use(router).provide('fbApp', fbApp).mount('#app')
    }
        getDatabase(fbApp), 
        getStorage(fbApp,"gs://cityscaper-studio")
  })
