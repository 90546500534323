<template>
  <div>
    <!--<router-link to="/context/P/-MnSO55B1KLpLARh9J5w">Huckarde</router-link>-->
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'HomeView',
  components: {
  }
})
</script>
